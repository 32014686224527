/**
 *  -----------------------------------------------
 *  PRESENTATION BLOCK
 *  -----------------------------------------------
 */

.presentation-block {
  padding-top: 12vw;
  padding-bottom: 15vw;
  @media (min-width: 1680px) {
    padding-top: 230px;
    padding-bottom: 250px;
  }
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
    margin-bottom: 15vw;
  }
  &__container {
    @include media-breakpoint-up(lg) {
      max-width: 1420px;
    }
  }
  .medium-title,
  .text {
    color: $white;
  }
  .medium-title {
    margin-bottom: 60px;
  }
  .text-editor {
    max-width: 570px;
    margin-left: auto;
    margin-right: 10%;
  }
  &__col-image {
    @include media-breakpoint-up(sm) {
      padding-right: 0;
    }
    @include media-breakpoint-up(lg) {
      order: 1;
    }
    @include media-breakpoint-down(md) {
      margin-top: 60px;
      margin-bottom: -60px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &__main-image {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 15px;
      right: 0;
      transform: translateY(260px);
    }
  }
  &__secondary-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(calc(-100% - 15px));
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  .projects-grid__image-wrapper {
    width: 100%;
    max-height: none;
    height: auto;
    padding-bottom: 126%;
    // max-height: 580px;
    // @media (max-width: $big-screen) {
    //   max-height: 545px;
    // }
    // @include media-breakpoint-down(lg) {
    //   max-height: 470px;
    // }
    // @include media-breakpoint-down(md) {
    //   max-height: 440px;
    // }
    // @include media-breakpoint-down(sm) {
    //   max-height: 550px;
    // }
    // @include media-breakpoint-down(xs) {
    //   max-height: 106vw;
    // }
  }
  &__col-text {
    @include media-breakpoint-up(lg) {
      order: 2;
    }
  }
}