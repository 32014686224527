/**
 *  -----------------------------------------------
 *  PUSH CONTACT
 *  -----------------------------------------------
 */

.push-contact {
  padding-top: 90px;
  padding-bottom: 90px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .small-title {
    margin-bottom: 40px;
    display: block;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 120px;
  }
}