/**
 *  -----------------------------------------------
 *  EXPERIENCE
 *  -----------------------------------------------
 */

.experience-page {
  // background-color: $pink-lighter;
  transition: background-color 1.3s $cubic-ease;
  &.is-bgwhite {
    background-color: $white;
  }
  &.is-main-bg {
    background-color: $main-bg;
  }
  .block-link {
    @include media-breakpoint-down(md) {
      padding-bottom: 28vh;
    }
    &__text {
      color: $white;
    }
  }
}

.experience {
  padding-top: 120px;
  margin-bottom: 60px;
  &__content {
    position: relative;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
  }
  &__image {
    position: relative;
    display: inline-block;
    max-width: 540px;
    z-index: 1;
    img {
      display: block;
    }
    // &::after {
    // 	@include overlay(0.15);
    // }
    @include media-breakpoint-down(lg) {
      max-width: 400px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 320px;
    }
    @include media-breakpoint-down(xs) {
      max-width: 220px;
    }
  }
  &__title-wrapper {
    text-align: center;
    width: 100%;
    z-index: 1;

    // .text-outer:nth-child(1) .text-inner {
      // transition-delay: 0.3s;
    // }
    // IE
    .is-ie & {
      position: absolute;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
    @include media-breakpoint-up(md) {
      position: fixed;
      top: 0;
      left: 0;
      // transform: translateY(-50%);
    }
    @include media-breakpoint-down(sm) {
      position: absolute;
      transform: translateY(-50%);
    }
  }
  &__title {
    @include main-title(6.4vw, $black);
    z-index: 2;
    @media (min-width: 1680px) {
      font-size: 10.8rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 4rem;
    }
    @include media-breakpoint-down(xs) {
      font-size: 3.3rem;
    }
  }
  &__text-wrapper {
    text-align: center;
    width: 100%;
    // IE
    .is-ie & {
      margin-top: 60px;
      transform: translate(0, 0) !important;
      opacity: 1;
      position: relative;
      left: 0;
      transform: translateX(0);
    }
    @include media-breakpoint-up(md) {
      position: fixed;
      top: 0;
      left: 0;
    }
  }
  &__text {
    transition: opacity 0.6s ease;
    width: 100%;
    max-width: 1190px;
    padding: 0 50px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
      padding: 0;
    }
  }
  .text-editor {
    font-size: 2.6rem;
    font-weight: 300;
    // letter-spacing: 0.02em;
    @include media-breakpoint-down(md) {
      font-size: 2.2rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.8rem;
    }
  }
  .blockquote {
    &__text {
      color: $black;
      font-size: 2.6rem;
      max-width: 1080px;
      @media (min-width: 1680px) {
        // font-size: 6rem;
        // max-width: none;
      }
    }
    @media (min-width: 1500px) and (max-width: 1599px) {
      margin-bottom: 120px;
    }
    @media (min-width: 1600px) {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      // &__text {
      //   font-size: 3.6rem;
      //   line-height: 1.5;
      //   max-width: 90%;
      // }
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 90px;
      // &__text {
      //   font-size: 2.5rem;
      //   max-width: none;
      // }
    }
    // @include media-breakpoint-down(xs) {
    //   &__text {
    //     font-size: 2rem;
    //   }
    // }
  }
}

.js-experience {
  min-height: 100vh;
}

.js-experience-title {
  @include media-breakpoint-up(lg) {
    opacity: 0;
  }
}