/**
 *  -----------------------------------------------
 *  SCROLL PROGRESS
 *  -----------------------------------------------
 */

.scroll-progress {
  position: fixed;
  top: 50%;
  left: 85px;
  transform: translateY(calc(-50% + 25px));
  z-index: 10;
  @include media-breakpoint-down(md) {
    display: none;
  }
  .projects-body &,
  .contact-body & {
    opacity: 0;
    visibility: hidden;
  }
  &__bar {
    height: 230px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      transform: translateY(-100%);
      background-color: $main;
    }
    .is-white-el & {
      background-color: rgba(255, 255, 255, 0.2);
      &::before {
        background-color: $white;
      }
    }
  }
  &__fill {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $main;
    height: 0;
    transition: height 0.5s $cubic-ease;
    .is-white-el & {
      background-color: $white;
    }
  }
}