/**
 *  -----------------------------------------------
 *  CONTACT
 *  -----------------------------------------------
 */

.contact {
  background-color: $main-bg;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    padding-bottom: 150px;
  }
  &__content {
    display: inline-block;
    position: relative;
    max-width: 600px;
    img {
      display: block;
    }
    .button {
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      @include media-breakpoint-down(sm) {
        &::before {
          // content: '';
          display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 10px;
          background: url('../images/ui/icon-phone.svg') 0 0 no-repeat;
          background-size: 100% auto;
          margin-right: 10px;
          position: relative;
          top: -1px;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      max-width: 450px;
    }
  }
  &__texts-wrapper {
    text-align: center;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(600px + 50%);
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    @include media-breakpoint-only(md) {
      width: calc(600px + 10%);
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 80px;
    }
  }
  &__text-wrapper {
    margin: 50px 0 60px;
    position: relative;
    @include media-breakpoint-down(sm) {
      margin: 30px 0;
      display: inline-block;
    }
  }
  .medium-title {
    @include media-breakpoint-up(lg) {
      font-size: 4.9vw;
    }
    @media (min-width: $xxl-screen) {
      font-size: 8rem;
    }
  }
  .text-editor {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      max-width: 80%;
      margin: 30px auto 0;
    }
  }
  &__mail {
    color: $white;
    font-size: 5vw;
    font-family: $sainteColombe;
    .hover-color__inner {
      color: $main;
    }
    @include media-breakpoint-up(md) {
      white-space: nowrap;
    }
    @include media-breakpoint-down(md) {
      font-size: 4.5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }
  }
  &__button {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}