/**
 *  -----------------------------------------------
 *  LOADER
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Loader
 -----------------------------------------------*/

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  transition: opacity 1s $cubic-op 1.8s, visibility 1s $cubic-op 1.8s, transform 1s $cubic 1.2s;
  .loading & {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }
  &__bckg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $main-bg;
    transform-origin: center top;
    transition: transform .8s cubic-bezier(.77, 0, .175, 1) 1.2s;
    .loading & {
      transform: scaleY(.0001);
      transition-delay: 1.2s;
    }
  }
  &__logo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &::after {
      @include overlay(1, $main-bg);
      transform-origin: right;
      transition: transform 0.8s $cubic;
    }
    .loading & {
      opacity: 0;
      transform: translate(-50%, -50%) translateY(-60px);
      transition: transform 0.6s $cubic 1s, opacity 0.8s $cubic-op 1.2s;
      &::after {
        transform: scaleX(0);
      }
    }
  }
}

.loader-logo {
  display: block;
  width: 420px;
  height: auto;
  @include media-breakpoint-down(sm) {
    width: 280px;
  }
}


/*-----------------------------------------------
 * Loader Pages Transition
 -----------------------------------------------*/

.loader-transition {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  transition: opacity 1s $cubic-op 1.8s, visibility 1s $cubic-op 1.8s, transform 1s $cubic 1.2s;
  &__bckg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $pink-dark;
    transform-origin: center top;
    transition: transform .8s cubic-bezier(.77, 0, .175, 1) 1.2s;
  }
  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.6s $cubic 1s, opacity 0.8s $cubic-op 1.2s;
  }
}