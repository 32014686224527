/**
 *  -----------------------------------------------
 *  SINGLE PROJECT
 *  -----------------------------------------------
 */

@mixin image-margin($side: bottom) {
  @if $side==bottom {
    margin-bottom: 180px;
  }
  @if $side==top {
    margin-top: 180px;
  }
  @include media-breakpoint-down(md) {
    @if $side==bottom {
      margin-bottom: 120px;
    }
    @if $side==top {
      margin-top: 120px;
    }
  }
  @include media-breakpoint-down(sm) {
    @if $side==bottom {
      margin-bottom: 90px;
    }
    @if $side==top {
      margin-top: 90px;
    }
  }
  @include media-breakpoint-down(xs) {
    @if $side==bottom {
      margin-bottom: 60px;
    }
    @if $side==top {
      margin-top: 60px;
    }
  }
}


/*-----------------------------------------------
 * Global
 -----------------------------------------------*/

.project {
  background-color: $white;
  &__link {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &__category {
    @extend .small-title;
    color: $grey-dark;
    font-size: 1.4rem;
    letter-spacing: 0.3em;
    margin-bottom: 50px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }
  &__dev {
    @extend .small-title;
    color: $grey-dark;
    font-size: 1.2rem;
    letter-spacing: 0.03em;
    text-transform: none;
    margin-top: 50px;
    a {
      @extend .simple-link;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }
  &__blockquote {
    text-align: center;
    @include image-margin();
    .blockquote {
      margin-top: 0 !important;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 60px;
    }
  }
  .blockquote {
    @include image-margin(top);
    &__text {
      color: $grey-dark;
      font-size: 4.9rem;
      max-width: 820px;
      @media (min-width: $xxl-screen) {
        font-size: 3.1vw;
        max-width: 50vw;
      }
      @include media-breakpoint-down(md) {
        font-size: 3.6rem;
        max-width: 540px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2.9rem;
        max-width: none;
      }
      @include media-breakpoint-down(xs) {
        font-size: 2.4rem;
      }
    }
  }
  .text-editor {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: $xxl-screen) {
      max-width: 50vw;
    }
    @include media-breakpoint-down(md) {
      max-width: 540px;
    }
    @include media-breakpoint-down(sm) {
      max-width: none;
    }
  }
  &__next {
    padding-top: 180px;
    position: relative;
    @include media-breakpoint-down(md) {
      padding-top: 120px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 90px;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 60px;
    }
    // Background
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 48.7%;
      background-color: $light-bg;
      @include media-breakpoint-down(md) {
        height: 45%;
      }
      @include media-breakpoint-down(sm) {
        height: 50%;
      }
    }
  }
  .block-link__content {
    max-width: 420px;
    @include media-breakpoint-down(lg) {
      max-width: 350px;
    }
  }
}


/*-----------------------------------------------
 * Project Screens
 -----------------------------------------------*/

.project-image {
  text-align: center;
  &:not(.is-full) {
    padding-top: 180px;
    padding-bottom: 180px;
    @include media-breakpoint-down(md) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 90px;
      padding-bottom: 90px;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &.is-full:last-child,
  &.has-bg {
    @include image-margin();
  }
  &.no-bg {
    padding-top: 0;
    padding-bottom: 0;
    @include image-margin();
  }
  &.no-bg.is-full+.is-full,
  &.no-bg.is-full+.has-bg,
  &.has-bg+.has-bg,
  &.has-bg+.is-full {
    margin-top: -180px;
    @include media-breakpoint-down(md) {
      margin-top: -120px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: -90px;
    }
    @include media-breakpoint-down(xs) {
      margin-top: -60px;
    }
  }
  &.is-full img {
    width: 100%;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}