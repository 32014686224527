/**
 *  -----------------------------------------------
 *  PROJECTS
 *  -----------------------------------------------
 */

.projects {
  background-color: $main-bg;
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    padding-top: 90px;
    padding-bottom: 150px;
    .main-title {
      margin-bottom: 60px;
    }
  }
  &-wrapper {
    width: 100%;
  }
  .main-title {
    @include main-title(14rem, $white);
    font-style: italic;
    line-height: 1.1em;
    position: relative;
    z-index: 2;
    @media (min-width: $xxl-screen) {
      font-size: 17.3rem;
      line-height: 0.9em;
    }
    @include media-breakpoint-down(md) {
      font-size: 12rem;
      line-height: 1.3em;
    }
    @include media-breakpoint-down(sm) {
      font-size: 7rem;
    }
    @include media-breakpoint-down(xs) {
      font-size: 5rem;
    }
  }
}

.projects-carousel {
  @include media-breakpoint-up(md) {
    transform: translateY(-40px);
  }
  &.is-center {
    .swiper-wrapper {
      @include media-breakpoint-up(md) {
        justify-content: center;
      }
    }
    &.has-1 {
      .projects-carousel__slide {
        @include media-breakpoint-only(md) {
          margin-right: 40px !important;
        }
      }
    }
    &.has-3 {
      .projects-carousel__slide {
        &:first-child {
          @media (min-width: 768px) and (max-width: 875px) {
            margin-left: 70px;
          }
        }
      }
    }
  }
  .swiper-wrapper {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &__slide {
    width: auto;
    transform: translateX(200%);
    opacity: 0;
    transition: transform 0.8s $cubic-ease, opacity 0.9s $cubic-op;
    .is-loaded & {
      transform: translateX(0);
      opacity: 1;
    }
    @for $i from 0 through 20 {
      &:nth-child(#{$i+1}) {
        transition-delay: 0.2s*($i/2);
      }
    }
  }
  &__progressbar {
    position: relative !important;
    width: 280px;
    height: 1px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.2);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.7s ease;
    @include media-breakpoint-down(md) {
      width: 230px;
    }
    .is-white-el & {
      background: rgba(255, 255, 255, 0.2);
    }
    .swiper-pagination-progressbar-fill {
      background-color: $main;
      .is-white-el & {
        background-color: $white;
      }
    }
    .no-carousel &-wrapper {
      display: none;
    }
    &.is-active {
      transform: scaleX(1);
    }
    &-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 130px;
      @media (min-width: #{$xxl-screen + 1px}) {
        bottom: 67px;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}