/**
 *  -----------------------------------------------
 *  FOOTER
 *  -----------------------------------------------
 */

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  padding: 30px 45px;
  &__text {
    display: inline-block;
    color: $black;
    font-size: 1.3rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    .is-white-el & {
      color: $white;
    }
    @media (max-width: $big-screen) {
      font-size: 1.1rem;
    }
    a {
      transition: color 0.5s ease;
      &:hover {
        color: $secondary;
      }
      .is-white-el &:hover {
        color: $black;
      }
    }
    &:not(:last-child) {
      &::after {
        content: '|';
        display: inline-block;
        color: currentColor;
        margin-left: 5px;
        margin-right: 3px;
      }
    }
    @media (max-width: 420px) {
      display: block;
      line-height: 1.7;
      &:not(:last-child)::after {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(md) {
    bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-down(sm) {
    .social {
      margin-bottom: 20px;
      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 15px;
        background-color: $black;
        margin: 15px auto 0;
        // @include media-breakpoint-up(md) {
        .is-white-el & {
          background-color: $white;
          // }
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    padding-left: 10px;
    padding-right: 10px;
  }
}