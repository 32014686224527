/**
 *  -----------------------------------------------
 *  TITLES
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Main Title
 -----------------------------------------------*/

@mixin main-title($fontsize, $color: $main, $underline: none) {
  display: inline-block;
  color: $color;
  font-family: $title;
  letter-spacing: 0.03em;
  font-size: $fontsize;
  font-weight: 300;
  line-height: 1.4;
  position: relative;
  @if $underline==underline {
    span::after {
      content: '';
      display: block;
      height: 3px;
      width: 100%;
      background-color: currentColor;
      position: relative;
      top: -1rem;
    }
  }
  @if $underline==none {
    span::after {
      background-color: transparent;
    }
  }
}

.main-title {
  @include main-title(8.6vw);
  @media (min-width: $xxl-screen) {
    font-size: 17.3rem;
    line-height: 0.9em;
  }
  @include media-breakpoint-down(md) {
    font-size: 8.5rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 7rem;
  }
  @include media-breakpoint-down(xs) {
    font-size: 5rem;
  }
}

.medium-title {
  @include main-title(4.5vw);
  @media (min-width: #{$xxl-screen + 1px}) {
    font-size: 7.1rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 5rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
  &.is-italic {
    font-style: italic;
  }
}


/*-----------------------------------------------
 * Small Title
 -----------------------------------------------*/

.small-title {
  display: inline-block;
  color: $main;
  font-size: 1.8rem;
  letter-spacing: 0.12em;
  line-height: 1.4;
  // text-indent: 0.9em;
  text-transform: uppercase;
  text-align: center;
  &.is-smaller {
    font-size: 1.4rem;
    letter-spacing: 0.3em;
  }
}


/*-----------------------------------------------
 * Background Title
 -----------------------------------------------*/

.bg-title {
  color: $white;
  -webkit-text-stroke: 1px $pink-light;
  font-family: $sainteColombe;
  font-size: 30rem;
  line-height: 1;
  opacity: 0.3;
}