/**
 *  -----------------------------------------------
 *  COOKIES
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Cookies
 -----------------------------------------------*/
.cookies {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 40px;
  text-align: center;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: 0.7;
    z-index: -1;
  }

  &__text {
    color: #FFF;
    font-size: 1.2rem;
    letter-spacing: 0.04rem;
    margin-bottom: 0;
  }

  &__close {
    display: inline-block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    @include media-breakpoint-down(xs) {
      width: 15px;
      height: 15px;
      top: 15px;
      right: 10px;
    }

    &::before,
    &::after {
      content: '';
      background-color: #FFF;
      position: absolute;
      top: 10px;
      width: 100%;
      height: 1px;

      @include media-breakpoint-down(xs) {
        height: 2px;
      }
    }

    &::before {
      left: 0;
      transform: rotate(45deg);
    }

    &::after {
      right: 0;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: #FFF;
      }
    }
  }
}