/**
 *  -----------------------------------------------
 *  PROJECT THUMBNAIL
 *  -----------------------------------------------
 */

.project-thumbnail {
  position: relative;
  width: 380px;
  @include media-breakpoint-between(md, lg) {
    max-width: 280px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto 8px;
    width: 350px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__link {
    display: inline-block;
    cursor: pointer;
    &:hover .project-thumbnail {
      &__image-wrapper {
        transform: scale(0.95);
        &::before {
          opacity: 0.7;
        }
      }
      &__content {
        opacity: 1;
      }
    }
  }
  &__image-wrapper {
    transition: transform 0.6s ease;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black;
      opacity: 0;
      transition: opacity 0.6s ease;
      .touchevents & {
        background: url('../images/ui/filter.svg') center center no-repeat;
        background-size: cover;
        opacity: 1;
      }
    }
  }
  &__image {
    display: block;
    width: 380px;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.8s ease;
    .touchevents & {
      opacity: 1;
    }
  }
  &__content-inner {
    max-width: 70%;
    text-align: center;
  }
  &__title {
    @include main-title(3.4rem,
    $white);
    display: inline-block;
    position: relative;
    // span {
    // 	display: inline;
    // 	border-bottom: 1px solid $pink-whiter;
    // }
    .touchevents & {
      position: absolute;
      left: 0;
      bottom: 30px;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
    }
    @include media-breakpoint-down(lg) {
      font-size: 2.6rem;
    }
    // @include media-breakpoint-down(xs) {
    //   font-size: 1.8rem;
    // }
  }
  &__category {
    display: block;
    color: $pink-whiter;
    font-size: 1.2rem;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    margin-top: 20px;
    position: relative;
    .touchevents & {
      display: none;
    }
  }
}