/**
 *  -----------------------------------------------
 *  ICONS
 *  -----------------------------------------------
 */

/*-----------------------------------------------
 * Icon
 -----------------------------------------------*/
.icon {
	display: inline-block;
	vertical-align: middle;
}