/**
 *  -----------------------------------------------
 *  MIXINS
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Overlay
 -----------------------------------------------*/
@mixin overlay($opacity: 0.5, $color: $black) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $color;
  opacity: $opacity;
}

/*-----------------------------------------------
 * Placeholder
 -----------------------------------------------*/
@mixin placeholder($color: #CCC, $fsize: 1.4rem, $transform: uppercase) {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
    font-size: $fsize;
    text-transform: $transform;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $color;
    font-size: $fsize;
    text-transform: $transform;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: $color;
    font-size: $fsize;
    text-transform: $transform;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $color;
    font-size: $fsize;
    text-transform: $transform;
  }
}

/*-----------------------------------------------
 * Custom scrollbar
 -----------------------------------------------*/
@mixin scrollbar($background: #EFEFEF, $thumb: #CCC, $width: 6px) {
  &::-webkit-scrollbar {
    width: $width;
    background-color: $background;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    cursor: pointer;
  }
}

/*-----------------------------------------------
 * Arrow
 -----------------------------------------------*/
@mixin arrow($direction, $size: 10px, $color: $black) {
  display: inline-block;
  width: $size;
  height: $size;
  border-right: 2px solid $color;
  border-bottom: 2px solid $color;

  @if $direction == down {
    transform: rotate(45deg);
  }
}

/*-----------------------------------------------
 * Square
 -----------------------------------------------*/
@mixin square-box($width: 50%, $min-width: 10px) {
    width: $width;
    min-width: $min-width;
    // overflow: hidden;

    .inner{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    img {
      display: block;
    }

    &::before{
        content: '';
        display: block;
        padding-top: 100%;
    }
}

/*
 * Triangle
 ****************************************************************/
@mixin triangle($direction, $sizeH, $sizeV, $color){
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  width: 0;

  @if $direction == top {
    border-color: transparent transparent $color transparent;
    border-width: 0 $sizeV $sizeH $sizeV;
  }

  @if $direction == bottom {
    border-color: $color transparent transparent transparent;
    border-width: $sizeV $sizeH 0 $sizeV;
  }

  @if $direction == left {
    border-color: transparent $color transparent transparent;
    border-width: $sizeV $sizeH $sizeV 0;
  }

  @if $direction == right {
    border-color: transparent transparent transparent $color;
    border-width: $sizeV 0 $sizeV $sizeH;
  }

  @if $direction == topright {
    border-color: transparent $color transparent transparent;
    border-width: 0 $sizeH $sizeV 0;
  }

  @if $direction == bottomright {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $sizeH $sizeV;
  }

  @if $direction == bottomleft {
    border-color: transparent transparent transparent $color;
    border-width: $sizeH 0 0 $sizeV;
  }

  @if $direction == topleft {
    border-color: $color transparent transparent transparent;
    border-width: $sizeH $sizeV 0 0;
  }
}

