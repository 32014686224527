/**
 *  -----------------------------------------------
 *  PRESTATIONS
 *  -----------------------------------------------
 */

.prestations {
  background-color: $main-bg;
  &__container {
    @include media-breakpoint-up(lg) {
      max-width: 1450px;
    }
  }
  &__list {
    text-align: center;
    padding-top: 150px;
    padding-bottom: 150px;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(lg) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @media (max-height: 768px) {
      min-height: 90vh;
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
  &__item {
    width: 100%;
  }
  &__link {
    @include main-title(9rem, $white, underline);
    span::after {
      top: -0.18em;
      height: 2px;
    }
    &.is-not-hover,
    .is-closed & {
      // color: $pink-lighter;
      opacity: 0.5;
    }
    .is-active &,
    &.is-hover {
      opacity: 1;
    }
    @include media-breakpoint-down(lg) {
      font-size: 6vw;
    }
    @include media-breakpoint-down(xs) {
      font-size: 4rem;
    }
  }
  &__detail-wrapper {
    margin: 0 auto;
    display: none;
  }
  &__detail {
    padding-top: 40px;
    padding-bottom: 40px;
    // max-width: 300px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
    &.one-column {
      text-align: center;
    }
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 1.6rem;
    }
    p {
      @include media-breakpoint-up(lg) {
        padding: 0 15px;
        flex: 0 0 33%;
        max-width: 33%;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
      br {
        display: block;
        &+br {
          display: none;
        }
      }
    }
    br {
      display: none;
    }
  }
  // Images
  &__images-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (max-height: 768px) {
      left: 80px;
      right: 80px;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &-image {
    position: absolute;
    opacity: 0;
    transition: opacity 1.2s $cubic-op, transform 1.5s $cubic-ease;
    max-width: 340px;
    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
    @include media-breakpoint-down(lg) {
      max-width: 280px
    }
    @media (max-height: 768px) {
      max-width: 230px;
    }
  }
}

// Images
.prestations-image {
  &__1 {
    transform: translateY(-12%);
  }
  &__2 {
    transform: translateY(12%);
  }
  &:first-child,
  &__logo-1 {
    top: 25%;
    left: 4%;
    // transform: translateY(12%);
  }
  &:nth-child(2),
  &__logo-2 {
    top: 10%;
    right: 5%;
    // transform: translateY(-12%);
  }
  &:nth-of-type(3),
  &:nth-child(3),
  &__branding-1 {
    top: 18%;
    left: 16%;
    @include media-breakpoint-down(md) {
      left: 10%;
    }
  }
  &:nth-child(4),
  &__branding-2 {
    top: 42%;
    right: 12%;
    @include media-breakpoint-down(md) {
      right: 7%;
    }
  }
  &:nth-child(5),
  &__supports-print-1 {
    top: 14%;
    left: 10%;
  }
  &:nth-child(6),
  &__supports-print-2 {
    top: 31%;
    right: 7%;
  }
  &:nth-child(7),
  &__packaging-1 {
    top: 35%;
    left: 15%;
    // transform: translateY(12%);
    @include media-breakpoint-down(md) {
      left: 12%;
    }
  }
  &:nth-child(8),
  &__packaging-2 {
    top: 7%;
    right: 9%;
    // transform: translateY(-12%);
    @include media-breakpoint-down(md) {
      right: 4%;
    }
  }
  &:nth-child(9),
  &__webdesign-1 {
    top: 10%;
    left: 0;
  }
  &:nth-child(10),
  &__webdesign-2 {
    top: 36%;
    right: 16%;
  }
  &:nth-child(11),
  &__ux-1 {
    top: 41%;
    left: 20%;
    // transform: translateY(12%);
    @include media-breakpoint-down(md) {
      left: 12%;
    }
  }
  &:nth-child(12),
  &__ux-2 {
    top: 8%;
    right: 0;
    // transform: translateY(-12%);
    @include media-breakpoint-down(md) {
      right: 5%;
    }
  }
}