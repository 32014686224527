/**
 *  -----------------------------------------------
 *  ANIMATIONS
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Text animation (outer / inner)
 -----------------------------------------------*/

.text-outer {
  display: block;
  overflow: hidden;
}

.text-inner {
  display: block;
  transform: translateY(100%);
  transition: transform 1.2s cubic-bezier(.165, .84, .44, 1);
  .is-active & {
    transform: translateY(0);
    @include media-breakpoint-down(sm) {
      transform: translateY(0);
    }
  }
}

@for $i from 0 through 6 {
  .text-outer:nth-child(#{1 + $i}) {
    .text-inner {
      transition-delay: #{0.15*$i}s;
    }
  }
}

@mixin reveal($color: $white, $direction: bottom) {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    background-color: $color;
    transform-origin: $direction;
    transition: transform 1s $cubic-ease;
    .is-loaded & {
      transform: scaleY(0);
    }
  }
}

.reveal {
  @include reveal();
  &-pink {
    @include reveal($pink-light);
  }
  &-beige {
    @include reveal($main-bg);
  }
}