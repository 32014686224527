/**
 *  -----------------------------------------------
 *  PROJECT BANNER
 *  -----------------------------------------------
 */

.project-banner {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  @extend .section-mb__180;
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__content {
    margin-bottom: 20px;
    .text-outer {
      padding-bottom: 5px;
    }
    .is-loaded & .text-inner {
      transform: translateY(0);
    }
    @include media-breakpoint-down(lg) {
      .touchevents & {
        transform: translateY(-25%);
      }
    }
  }
  &__title {
    @include main-title(6.3vw, $white);
    display: inline-block;
    position: relative;
    // max-width: 580px;
    max-width: 80vw;
    padding: 0 20px;
    text-align: center;
    span {
      display: inline;
      // border-bottom: 2px solid currentColor;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-position: under;
    }
    @media (min-width: $xxl-screen) {
      font-size: 10rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 7.5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 11vw;
      max-width: none;
    }
  }
}