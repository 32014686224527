/**
 *  -----------------------------------------------
 *  COLORS
 *  -----------------------------------------------
 */

/*-----------------------------------------------
 * Colors
 -----------------------------------------------*/
.color {
	&-black { color: $black; }
	&-white { color: $white; }
	&-pink-light { color: $pink-light; }
}


/*-----------------------------------------------
 * Background Colors
 -----------------------------------------------*/
.background {
	&-black { background-color: $black; }
	&-white { background-color: $white; }
	&-pink-white { background-color: $pink-white; }
	&-main-bg { background-color: $main-bg; }
}