/**
 *  -----------------------------------------------
 *  MENU
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Menu
 -----------------------------------------------*/

.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  background-color: $dark-bg;
  z-index: 21;
  transform: translateX(105%);
  transition: transform .8s $cubic-ease;
  @include media-breakpoint-down(lg) {
    width: 75%;
  }
  // Vertical line
  @include media-breakpoint-up(lg) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 1px;
      background-color: $pink-white;
      opacity: 0.1;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.6s $cubic 0.6s;
    }
    &.is-active::before {
      transform: scaleY(1);
    }
  }
  @include media-breakpoint-down(xs) {
    width: 86%;
  }
  &__container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__row {
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
  &__col-left {
    @include media-breakpoint-down(md) {
      // Horizontal line
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 130%;
        height: 1px;
        background-color: $pink-white;
        opacity: 0.1;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.6s $cubic 0.6s;
      }
      .menu.is-active &::after {
        transform: scaleX(1);
      }
    }
  }
  &__col-left,
  &__col-right {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 15vw;
    }
  }
  &__col-right {
    padding-top: 15px;
    padding-right: 3vw;
    @include media-breakpoint-down(md) {
      padding-top: 50px;
    }
  }
  &__col-right-inner {
    display: inline-block;
    text-align: left;
    .button {
      color: $white;
      letter-spacing: 0.3em;
      text-indent: 0.3em;
      &:hover {
        color: $main-bg;
      }
      @include media-breakpoint-down(xs) {
        font-size: 1rem;
      }
    }
  }
  // Open
  &.is-active {
    transform: translateX(0);
  }
}


/*-----------------------------------------------
 * Navigation
 -----------------------------------------------*/

.navigation {
  display: inline-block;
  text-align: -webkit-left;
  list-style-type: none;
  counter-reset: li;
  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }
  &__item {
    display: block;
    font-family: $sainteColombe;
    font-size: 3vw;
    color: $white;
    letter-spacing: 0.2rem;
    transition: color 1s $cubic-op;
    text-align: left;
    &::before {
      counter-increment: li;
      content: counter(li, decimal-leading-zero) '.';
      font-family: $proxima;
      font-weight: 300;
      font-size: 0.3333em;
      margin-right: 20px;
    }
    &:not(:last-child) {
      margin-bottom: 50px;
    }
    .no-touchevents &:hover {
      &>.navigation__link {
        transform: translateX(30px);
      }
    }
    &.is-not-hover {
      &,
      &>.navigation__link {
        color: $main-bg;
      }
    }
    @include media-breakpoint-down(md) {
      font-size: 4rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
    @include media-breakpoint-down(xs) {
      font-size: 2.2rem;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      &::before {
        font-size: 0.4em;
        margin-right: 10px;
      }
    }
  }
  &__link {
    display: inline-block;
    color: $white;
    transition: color 1s $cubic-op, transform 0.8s ease;
  }
}


/*-----------------------------------------------
 * Toggle Menu
 -----------------------------------------------*/

$toggle-menu-width: 85px;
$toggle-menu-width-sm: 70px;
.toggle-menu {
  position: fixed;
  top: 50%;
  right: 30px;
  width: $toggle-menu-width;
  height: $toggle-menu-width;
  transform: translateY(-50%);
  border-radius: 50%;
  z-index: 22;
  @include media-breakpoint-down(sm) {
    width: $toggle-menu-width-sm;
    height: $toggle-menu-width-sm;
    top: auto;
    bottom: 30px;
    transform: translateY(0);
  }
  // background
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: $main;
    border-radius: 50%;
    z-index: -1;
    transition: transform 0.5s $cubic-ease;
    @include media-breakpoint-down(lg) {
      opacity: 0.9;
    }
  }
  &__text {
    display: block;
    color: $white;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: opacity 0.3s $cubic-op;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }
  &__bar {
    position: absolute;
    width: $toggle-menu-width*0.35;
    height: 1px;
    background-color: $white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: opacity 0.5s, transform 0.8s ease;
    &:last-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    .is-white-el & {
      background-color: $white;
    }
    @include media-breakpoint-down(sm) {
      width: $toggle-menu-width-sm*0.35;
    }
  }
  .no-touchevents &:hover {
    &::before {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
  &.is-active {
    .toggle-menu__text {
      opacity: 0;
    }
    .toggle-menu__bar {
      opacity: 1;
    }
  }
}