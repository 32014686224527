// Typography
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$black:		  	#000;
$white:			  #FFF;
$grey-dark:	  #333333;
// $old:			#DD545B;
$main:			  #000;
$secondary:   #8E7565;
$pink-dark:   #B5434A;
$pink-dull: 	#72545A;
$pink-light:  #E3A39C;
$pink-lighter:#F2CDC3;
$pink-white: 	#FFEAE2;
$pink-whiter: #FFEEE6;
$main-bg: 	  #DFD3C7;
$dark-bg: 	  #BAA697;
$light-bg: 	  #F7F4F2;
 
// Fonts
$proxima: 'ProximaNova', sans-serif;
$sainteColombe: 'SainteColombe', sans-serif;
$butler: 'Butler', sans-serif;
$ivypresto: "ivypresto-display", serif;   // Regular 400 - Light 300 + Italic
$acumin: "acumin-pro-wide", sans-serif;   // Bold 700 - Medium 500 - Regular 400 - Light 300 + Italic

$text: $acumin;
$title: $ivypresto;

// Screen
$xxl-screen: 1620px;
$big-screen: 1350px;


// Animations
$cubic-ease: cubic-bezier(.25,.05,.1,.94);
$cubic: cubic-bezier(0.5,.05,.700,0.2);
$cubic-op: cubic-bezier(0.2,0.6,0.35,1);