/**
 *  -----------------------------------------------
 *  PROJECTS GRID
 *  -----------------------------------------------
 */

.projects-grid {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 180px;
  padding-top: 250px;
  padding-bottom: 20px;
  position: relative;
  @media (max-width: 1620px) {
    padding-top: 180px;
  }
  @include media-breakpoint-between(md, lg) {
    padding-top: 0;
    margin-bottom: 0;
    transform: scale(0.9);
  }
  @include media-breakpoint-down(sm) {
    padding-top: 60px;
    margin-bottom: 0;
  }
  .bg-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    &.is-fixed {
      position: fixed;
      transform: translateY(-50%);
    }
  }
  &__maintitle {
    @extend .medium-title;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  &__container {
    margin-top: 140px;
    margin-bottom: 200px;
    @media (max-width: 1620px) {
      margin-bottom: 150px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 60px;
      margin-bottom: 90px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }
  &__row-1 {
    @include media-breakpoint-up(md) {
      &:not(:last-child) {
        margin-bottom: 480px;
      }
    }
  }
  &__row-2 {
    @include media-breakpoint-up(md) {
      margin-bottom: 680px;
      &:last-child {
        margin-bottom: 200px;
      }
    }
  }
  &__row-1 .projects-grid__col:first-child,
  &__row-3 .projects-grid__col:first-child,
  &__row-2 .projects-grid__col:last-child {
    z-index: 1;
    .projects-grid__image-wrapper {
      width: 520px;
      max-height: 640px;
      @media (max-width: $big-screen) {
        width: 460px;
        max-height: 565px;
      }
      @include media-breakpoint-down(md) {
        width: 320px;
        max-height: 440px;
      }
      @include media-breakpoint-down(sm) {
        width: 460px;
        max-height: 565px;
      }
      @include media-breakpoint-down(xs) {
        width: 300px;
        max-height: 410px;
      }
    }
  }
  &__col {
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
  }
  &__project {
    position: relative;
    display: inline-block;
    img {
      display: block;
    }
    &.project-1 {
      display: block;
      text-align: right;
      .projects-grid__title {
        top: 30%;
        left: auto;
        right: 0;
        transform: translateX(40%);
        text-align: left;
      }
      @include media-breakpoint-down(lg) {
        transform: translateX(10%);
      }
      @include media-breakpoint-up(md) {
        .projects-grid__link:hover .projects-grid__title {
          transform: translateX(50%);
        }
      }
    }
    &.project-2 {
      transform: translateY(70%);
      .projects-grid__title {
        top: 75%;
      }
      @include media-breakpoint-down(lg) {
        transform: translate(15%, 70%);
      }
    }
    &.project-3 {
      transform: translate(-20%, 70%);
      .projects-grid__title {
        top: 65%;
        left: auto;
        right: 0;
        transform: translateX(40%);
        text-align: left;
      }
      @include media-breakpoint-up(md) {
        .projects-grid__link:hover .projects-grid__title {
          transform: translateX(50%);
        }
      }
      @media (min-width: 1920px) {
        transform: translate(-60%, 70%);
      }
      @media (max-width: 1500px) {
        transform: translate(0%, 70%);
      }
      @media (min-width: 1201px) and (max-width: $big-screen) {
        transform: translate(20%, 70%);
      }
      @include media-breakpoint-down(lg) {
        transform: translate(10%, 70%);
      }
      @include media-breakpoint-down(md) {
        transform: translate(3%, 70%);
      }
    }
    &.project-4 {
      transform: translateX(-20%);
      .projects-grid__title {
        top: 40%;
      }
      @media (min-width: 1920px) {
        transform: translateX(-40%);
      }
      @media (max-width: 1500px) {
        transform: translateX(-10%);
      }
    }
    &.project-5 {
      display: block;
      text-align: right;
      transform: translateX(40%);
      .projects-grid__title {
        left: auto;
        right: 0;
        transform: translateX(60%);
        text-align: left;
      }
      @media (max-width: 1500px) {
        transform: translateX(20%);
      }
      @include media-breakpoint-up(md) {
        .projects-grid__link:hover .projects-grid__title {
          transform: translateX(70%);
        }
      }
      @include media-breakpoint-down(lg) {
        transform: translateX(15%);
      }
      @include media-breakpoint-down(md) {
        transform: translateX(5%);
      }
    }
    &.project-6 {
      display: block;
      text-align: right;
      transform: translate(20%, -85%);
      .projects-grid__title {
        left: auto;
        right: 50%;
        transform: translate(10%, -50%);
      }
      @media (max-width: 1500px) {
        transform: translate(0%, -85%);
      }
      @include media-breakpoint-up(md) {
        .projects-grid__link:hover .projects-grid__title {
          transform: translate(0, -50%);
        }
      }
      @media (min-width: 992px) and (max-width: 1350px) {
        transform: translate(-10%, -85%);
      }
    }
    &.projects-grid__project {
      @include media-breakpoint-down(sm) {
        display: block;
        transform: none;
        text-align: center;
      }
    }
  }
  &__link {
    display: inline-block;
    .no-touchevents &:hover .projects-grid {
      &__title {
        opacity: 1;
      }
    }
    @include media-breakpoint-up(md) {
      &:hover .projects-grid {
        &__title {
          transform: translate(-50%, -50%);
        }
        &__image {
          transform: scale(0.95);
          &::after {
            opacity: 0.5;
          }
        }
      }
    }
    .touchevents & {
      position: relative;
    }
  }
  &__image-wrapper {
    display: inline-block;
    width: 380px;
    height: 100vh;
    max-height: 520px;
    overflow: hidden;
    position: relative;
    @media (max-width: $big-screen) {
      width: 320px;
      max-height: 440px;
    }
    @include media-breakpoint-down(md) {
      width: 280px;
      max-height: 380px;
    }
    @include media-breakpoint-down(sm) {
      width: 460px;
      max-height: 565px;
    }
    @include media-breakpoint-down(xs) {
      width: 300px;
      max-height: 410px;
    }
  }
  &__image-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__bg-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 110%;
    width: auto;
    background-size: cover;
    background-position: 50%;
  }
  // &__image {
  // 	display: inline-block;
  // 	position: relative;
  // 	transition: transform 0.8s $cubic-ease;
  // 	&::after {
  // 		content: '';
  // 		position: absolute;
  // 		top: 0;
  // 		left: 0;
  // 		bottom: 0;
  // 		right: 0;
  // 		background-color: $black;
  // 		opacity: 0;
  // 		transition: opacity 0.6s $cubic-ease;
  // 	}
  // }
  &__title {
    @extend .medium-title;
    color: $secondary;
    text-align: right;
    max-width: 380px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-40%, -50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.6s, transform 0.8s $cubic-ease;
    span {
      display: inline;
      border-bottom: 1px solid currentColor;
    }
    .touchevents &.projects-grid__title {
      opacity: 1;
      color: $white;
      font-size: 2.5rem;
      top: auto;
      right: auto;
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%);
      max-width: none;
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      .projects-grid__project &.projects-grid__title {
        font-size: 1.8rem;
        top: auto;
        right: auto;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        max-width: none;
        text-align: center;
      }
    }
  }
}