/**
 *  -----------------------------------------------
 *  HOME
 *  -----------------------------------------------
 */

.home {
	.blockquote__text {
		@include media-breakpoint-down(sm) {
			font-size: 2.5rem;
		}
	}
}