/**
 *  -----------------------------------------------
 *  ERROR 404
 *  -----------------------------------------------
 */

.error {
  &-html,
  &-body {
    height: 100%;
  }
  &-page {
    background-color: $dark-bg;
  }
  &__content {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    .button {
      color: $white;
      @include media-breakpoint-down(sm) {
        letter-spacing: 0.2em;
      }
    }
  }
  &__title {
    @include main-title(23rem, $white);
    display: block;
    @include media-breakpoint-down(lg) {
      font-size: 15rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 10rem;
    }
  }
  &__subtitle {
    @include main-title(5rem, $white);
    display: block;
    margin-bottom: 50px;
    @include media-breakpoint-down(lg) {
      font-size: 4rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2.6rem;
    }
  }
}