/**
 *  -----------------------------------------------
 *  MAINSCREEN
 *  -----------------------------------------------
 */

.mainscreen {
  min-height: 100vh;
  position: relative;
  margin-bottom: 75vh;
  @include media-breakpoint-down(md) {
    margin-bottom: 90px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 60px;
    padding-top: 40px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 100vh;
  }
  &__col-left {
    width: 100%;
    @include media-breakpoint-up(lg) {
      flex: 0 0 58%;
      max-width: 58%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      max-width: 530px;
      margin: 0 auto;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 30px;
    }
  }
  &__col-image {
    position: relative;
    // Animation in load
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -1px;
      right: -1px;
      bottom: 0;
      background-color: $light-bg;
      transform-origin: bottom;
      transition: transform 1s $cubic-ease;
      .is-loaded & {
        transform: scaleY(0);
      }
    }
  }
  &__col-left-image {
    display: inline-block;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      max-width: 45%;
      opacity: 0.9;
    }
  }
  &__col-right {
    width: 100%;
    img {
      width: 100%;
    }
    &.is-cover {
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &::after {
      transition-duration: 1.2s;
      transition-delay: 0.1s;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 42%;
      max-width: 42%;
      height: auto;
    }
    @include media-breakpoint-down(md) {
      max-width: 530px;
      margin: 0 auto;
    }
  }
  &__title-box {
    text-align: center;
    @include media-breakpoint-up(lg) {
      position: absolute;
      // top: 50%;
      left: 0;
      // transform: translateY(-50%);
      width: 100%;
      text-align: center;
      z-index: 1;
    }
    @include media-breakpoint-down(md) {
      margin: 80px 0 20px;
    }
  }
  &__title {
    @extend .main-title;
    display: inline-block;
    // color: $main;
    // font-family: $title;
    // font-size: 7.95vw;
    line-height: 1.08;
    // letter-spacing: 0.02em;
    margin-bottom: 0;
    small {
      display: block;
    }
    @include media-breakpoint-between(md, lg) {
      img {
        max-width: 45vw;
      }
    }
    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 20px;
      line-height: 1.2;
    }
    @include media-breakpoint-down(sm) {
      font-size: 5rem;
      margin-bottom: 15px;
    }
  }
  &__title-el-wrapper {
    display: block;
    overflow: hidden;
  }
  &__title-el {
    display: block;
    transform: translateY(100%);
    transition: transform 1.2s cubic-bezier(.165, .84, .44, 1);
    .is-loaded & {
      transform: translateY(0);
    }
    .is-loaded .is-title & {
      transform: translateY(-9%);
      @include media-breakpoint-down(sm) {
        transform: translateY(0);
      }
    }
  }
  &__title-logo {
    display: block;
    max-width: 650px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-inline: auto;
    }
  }
  .small-title {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: calc(50% + 300px/2);
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
      top: 0 !important;
      margin-bottom: 80px;
    }
  }
}

@include media-breakpoint-down(md) {
  .is-md-hidden {
    display: none;
  }
}