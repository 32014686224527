/**
 *  -----------------------------------------------
 *  FONTS
 *  -----------------------------------------------
 */

/*-----------------------------------------------
 * Proxima Nova
 -----------------------------------------------*/

// Regular
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Regular.eot?#iefix');
    src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('eot'),
         url('../fonts/ProximaNova-Regular.woff') format('woff'),
         url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
         url('../fonts/ProximaNova-Regular.svg') format('svg'),
         url('../fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

// Light
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Light.eot?#iefix');
    src: url('../fonts/ProximaNova-Light.eot?#iefix') format('eot'),
         url('../fonts/ProximaNova-Light.woff') format('woff'),
         url('../fonts/ProximaNova-Light.svg') format('svg'),
         url('../fonts/ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

// Thin
@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNova-Thin.eot?#iefix');
    src: url('../fonts/ProximaNova-Thin.eot?#iefix') format('eot'),
         url('../fonts/ProximaNova-Thin.woff') format('woff'),
         url('../fonts/ProximaNova-Thin.svg') format('svg'),
         url('../fonts/ProximaNova-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

/*-----------------------------------------------
 * Sainte Colombe
 -----------------------------------------------*/

// Light
@font-face {
    font-family: 'SainteColombe';
    src: url('../fonts/SainteColombe-Light.eot?#iefix');
    src: url('../fonts/SainteColombe-Light.eot?#iefix') format('eot'),
         url('../fonts/SainteColombe-Light.woff') format('woff'),
         url('../fonts/SainteColombe-Light.woff2') format('woff2'),
         url('../fonts/SainteColombe-Light.svg') format('svg'),
         url('../fonts/SainteColombe-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/*-----------------------------------------------
 * Butler
 -----------------------------------------------*/

// Light
@font-face {
    font-family: 'Butler';
    src: url('../fonts/Butler-Light.eot?#iefix');
    src: url('../fonts/Butler-Light.eot?#iefix') format('eot'),
         url('../fonts/Butler-Light.woff') format('woff'),
         url('../fonts/Butler-Light.woff2') format('woff2'),
         url('../fonts/Butler-Light.svg') format('svg'),
         url('../fonts/Butler-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}