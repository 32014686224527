/**
 *  -----------------------------------------------
 *  MARGINS
 *
 *  1. Margins
 *  2. Paddings
 *  3. Sections Paddings
 *  4. Sections Margin Bottom
 *  -----------------------------------------------
 */


/*
 * 1. Margins
 ****************************************************************/
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}


/*
 * 2. Paddings
 ****************************************************************/
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.no-padding {
    padding: 0 !important;
}
.no-margin {
    margin: 0 !important;
}

/*
 * 3. Sections Paddings
 ****************************************************************/
.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &--small {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &--medium {
    padding-top: 90px;
    padding-bottom: 90px;

    @include media-breakpoint-down(md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &--big {
    padding-top: 120px;
    padding-bottom: 120px;

    @include media-breakpoint-down(md) {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

/*
 * 4. Sections Margin Bottom
 ****************************************************************/
.section-mb {
  &__180 {
    margin-bottom: 180px;

    @include media-breakpoint-down(md) {
      margin-bottom: 120px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
  }

  &__150 {
    margin-bottom: 150px;

    @include media-breakpoint-down(md) {
      margin-bottom: 90px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
  }

	&__120 {
    margin-bottom: 120px;

    @include media-breakpoint-down(md) {
      margin-bottom: 90px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
  }

  &__90 {
    margin-bottom: 90px;

    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  &__60 {
    margin-bottom: 60px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }
}