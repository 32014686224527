/**
 *  -----------------------------------------------
 *  EDITOR
 *  -----------------------------------------------
 */

.text-editor {
  @extend .text;
  p,
  ul,
  ol {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
  a {
    text-decoration: underline;
    text-underline-position: under;
    transition: all 0.3s $cubic-ease;
    &:hover {
      text-decoration-color: transparent;
    }
  }
}