/**
 *  -----------------------------------------------
 *  DEFAULT PAGE
 *  -----------------------------------------------
 */

.default {
	padding-top: 180px;
	padding-bottom: 150px;

	@include media-breakpoint-down(lg) {
		padding-top: 120px;
	}

	&-container {
		max-width: 760px;
		margin: 0 auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	.main-title {
		font-size: 9rem;
		margin-bottom: 90px;

		@include media-breakpoint-down(lg) {
			font-size: 7rem;
			margin-bottom: 60px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 5rem;
		}

		@include media-breakpoint-down(xs) {
			font-size: 3.6rem;
			margin-bottom: 30px;
		}
	}

	h2 {
		@include main-title(2.2rem);
		margin-bottom: 20px;

		&::after {
			content: '';
			display: block;
			height: 1px;
			width: 100%;
			background-color: currentColor;
			position: relative;
			top: -3px;
		}

		&:not(:first-child) {
			margin-top: 20px;
		}
	}

	p {
		color: $main;
    	font-size: 1.4rem;
	    line-height: 1.5;
	    letter-spacing: 0.1rem;

	    &:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	a {
		transition: color 0.5s ease;

		&:hover {
			color: $pink-light;
			text-decoration: underline;
		}
	}
}
