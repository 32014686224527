/**
 *  -----------------------------------------------
 *  HEADER
 *  -----------------------------------------------
 */

.header {
}

/*-----------------------------------------------
 * Logo
 -----------------------------------------------*/
.main-logo {
	position: fixed;
	top: 60px;
	left: 60px;
	z-index: 20;

	@include media-breakpoint-down(md) {
		top: 30px;
		left: 30px;
	}

	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 20px;
	}
}

.logo {
	img {
		width: 45px;
		height: 45px;
		transition: opacity 0.5s $cubic-op;
	}

	&-white {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.5s ease;

		.is-white-el & {
			opacity: 1;
		}
	}

	&-main {
		.is-white-el & {
			opacity: 0;
		}
	}
}


