// Variables
@import 'common/variables';

// Bootstrap
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import '~bootstrap/scss/images';
@import "~bootstrap/scss/utilities";

// Plugins
@import 'plugins/swiper.min.css';

// Common
@import 'common/reset';
@import 'common/fonts';
@import 'common/global';
@import 'common/mixins';

// Components
@import 'components/titles';
@import 'components/buttons';
@import 'components/icons';
@import 'components/social';
@import 'components/menu';
@import 'components/scroll-progress';
@import 'components/cookies';
@import 'components/loader';
@import 'components/animations';
@import 'components/editor';

// Layout
@import 'layout/header';
@import 'layout/footer';

// Modules
@import 'modules/mainscreen';
@import 'modules/blockquote';
@import 'modules/projects-grid';
@import 'modules/push-contact';
@import 'modules/block-link';
@import 'modules/push-webproject';
@import 'modules/project-thumbnail';
@import 'modules/project-banner';
@import 'modules/presentation-block';

// Pages
// @import 'pages/home';
@import 'pages/home';
@import 'pages/prestations';
@import 'pages/projects';
@import 'pages/single-project';
@import 'pages/experience';
@import 'pages/contact';
@import 'pages/default';
@import 'pages/error-404';

// Common
@import 'common/margins';
@import 'common/colors';