/**
 *  -----------------------------------------------
 *  PUSH WEB PROJET
 *  -----------------------------------------------
 */

.push-webproject {
  position: relative;
  min-height: 100vh;
  width: 100%;
  // &:before {
  // 	content: '';
  // 	display: block;
  // 	padding-top: 56.25%;
  // 	position: relative;
  // 	width: 100%;
  // }
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 15px 150px;
  }
  &__bckg {
    background-color: $dark-bg;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translateX(-50%);
    z-index: -1;
    @include media-breakpoint-up(lg) {
      width: 80%;
      height: 100vh;
      overflow: hidden;
      border-top: 90px solid $main-bg;
      border-bottom: 90px solid $main-bg;
      border-left: 150px solid $main-bg;
      border-right: 150px solid $main-bg;
      transition: border-width 1s $cubic-ease;
      .is-ie & {
        width: 100%;
        border: 0;
      }
    }
    .is-fixed & {
      border-width: 0;
    }
  }
  &__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
  }
  &__container {
    @include media-breakpoint-up(lg) {
      max-width: 75vw;
    }
    @media (min-width: #{$xxl-screen + 1px}) {
      max-width: 1280px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .blockquote__text {
      max-width: 60vw;
      margin: 0 auto;
      &.is-title {
        font-size: 5vw;
      }
    }
    .is-ie & {
      height: 100vh;
    }
    .is-ie &.is-second {
      background-color: $pink-lighter;
    }
    @include media-breakpoint-up(lg) {
      min-height: 100vh;
    }
    @include media-breakpoint-down(lg) {
      .blockquote__text.is-title {
        font-size: 6rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
      .blockquote__text {
        max-width: 70vw;
      }
    }
    @include media-breakpoint-down(sm) {
      .blockquote__text.is-title {
        font-size: 3.5rem;
      }
    }
    @include media-breakpoint-down(xs) {
      .blockquote__text {
        max-width: none;
      }
    }
  }
  .medium-title {
    @media (min-width: $xxl-screen) {
      font-size: 9.1rem;
    }
  }
  .text-editor {
    @media (min-width: #{$big-screen + 1px}) {
      font-size: 2.6rem;
    }
  }
  &__logos {
    color: $white;
    display: inline-block;
    vertical-align: middle;
    margin-top: 35px;
    .logo {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
      font-size: 10px;
      .logo {
        height: 11px;
        width: auto;
        margin: 0 6px;
      }
    }
  }
  &__links {
    margin-top: 60px;
    @include media-breakpoint-down(xs) {
      margin-top: 30px;
    }
    // .button {
    //   margin: 0 30px;
    //   &:not(:last-child) {
    //     margin-bottom: 30px;
    //   }
    // }
  }
}

.has-push-webproject {
  .footer__text {
    color: $white;
  }
  .social__item {
    @include media-breakpoint-down(sm) {
      color: $white;
    }
  }
  .footer .social::after {
    @include media-breakpoint-down(sm) {
      background-color: $white;
    }
  }
  .social-plus .icon svg * {
    @include media-breakpoint-down(sm) {
      fill: $white;
    }
  }
}