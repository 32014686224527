/**
 *  -----------------------------------------------
 *  BLOCK LINK
 *  -----------------------------------------------
 */

.block-link {
  text-align: center;
  padding-bottom: 20vh;
  @include media-breakpoint-down(sm) {
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 150px;
  }
  &__content {
    display: inline-block;
    position: relative;
    max-width: 480px;
    text-align: center;
    &>a {
      display: block;
      &:hover {
        .block-link__image {
          transform: translate3d(0, 0, 0, ) scale(1.01);
        }
      }
    }
    @include media-breakpoint-down(lg) {
      max-width: 380px;
    }
  }
  &__image-wrapper {
    display: block;
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      max-width: 230px;
    }
  }
  &__image {
    display: block;
    transform: translate3d(0, 0, 0, ) scale(1.04);
    transition: transform 0.8s ease;
  }
  &__text {
    display: inline-block;
    color: $main;
    font-family: $title;
    font-weight: 300;
    letter-spacing: 0.03em;
    // font-size: 6.7vw;
    width: max-content;
    z-index: 1;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    font-size: 5rem;
    margin-bottom: 30px;
    text-align: center;
    .hover-color:not(.no-line) & .hover-color__inner::after,
    &::after {
      content: '';
      display: block;
      // height: 4px;
      width: 100%;
      background-color: currentColor;
      position: relative;
      // top: -15px;
      // top: -1.2rem;
      top: -1rem;
      height: 2px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 4rem;
    }
    // @include media-breakpoint-down(md) {
    // 	font-size: 7rem;
    // }
    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
      // &::after {
      // 	height: 2px;
      // 	top: -0.7rem;
      // }
      .hover-color:not(.no-line) & .hover-color__inner::after,
      &::after {
        height: 1px;
        top: -0.6rem;
      }
    }
    @include media-breakpoint-down(xs) {
      // font-size: 4rem;
    }
  }
  &__back {
    margin-top: 10px;
    @include media-breakpoint-up(md) {
      transform: translateY(30px);
    }
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}

// With big link in center of image
.block-link.is-big .block-link {
  &__text {
    font-size: 12.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include media-breakpoint-down(lg) {
      font-size: 8rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 4rem;
    }
  }
}