/**
 *  -----------------------------------------------
 *  BUTTONS
 *  -----------------------------------------------
 */

.js-hover-link {
  transition: color 1s, opacity 0.8s $cubic-op;
}


/*-----------------------------------------------
 * Button
 -----------------------------------------------*/

.button {
  display: inline-block;
  color: $main;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.12em;
  text-indent: 0.4em;
  text-transform: uppercase;
  position: relative;
  transition: color 0.5s ease;
  @include media-breakpoint-down(sm) {
    font-size: 1.4rem;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -0.4rem;
    left: 0.1em;
    right: 0.1em;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.5s ease-out;
  }
  &:hover {
    color: $main;
    &::after {
      transform: scaleX(1.2);
    }
  }
  &.is-white {
    color: $white;
  }
}


/*-----------------------------------------------
 * Simple link
 -----------------------------------------------*/

.simple-link {
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.5s ease-out;
  }
  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}


/*-----------------------------------------------
 * Hover Color
 -----------------------------------------------*/

.hover-color {
  .no-touchevents &:hover {
    .hover-color {
      &__outer {
        width: 100%;
      }
      &__outer-b {
        opacity: 0;
        transition-delay: 0.5s;
      }
    }
  }
  &__outer {
    position: absolute;
    top: 0;
    left: 0;
    right: -0.1px;
    bottom: 0;
    overflow: hidden;
    width: 0;
    transition: width 0.45s $cubic;
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 9999px;
    width: max-content;
    color: $secondary;
    text-align: left;
  }
  &__outer-b {
    transition: opacity 0.5s ease;
  }
  // Underline
  &:not(.no-line) .hover-color__inner::after {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    background-color: currentColor;
    position: relative;
    // top: -15px;
    top: -1.2rem;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      height: 2px;
      top: -0.7rem;
    }
  }
}