/**
 *  -----------------------------------------------
 *  SOCIAL NETWORKS
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Social Networks
 -----------------------------------------------*/

.social {
  &__item {
    color: $black;
    display: inline-block;
    font-size: 1.3rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    @media (max-width: $big-screen) {
      font-size: 1.1rem;
    }
    &:not(:last-child) {
      margin-right: 30px;
      @include media-breakpoint-down(md) {
        margin-right: 20px;
      }
      @include media-breakpoint-down(xs) {
        margin-right: 15px;
      }
    }
    @include media-breakpoint-up(md) {
      .is-white-el & {
        color: $white;
      }
    }
  }
  &:not(.social-plus) {
    .is-instagram {
      .icon svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  &__link {
    transition: color 0.5s ease;
    &:hover {
      color: $secondary;
      .icon svg * {
        fill: $main-bg;
      }
    }
    .is-white-el &:hover {
      color: $black;
    }
    &.is-behance,
    &.is-instagram {
      line-height: 1;
    }
    &.is-pinterest {
      .icon svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .icon svg {
    width: 20px;
    height: 20px;
    * {
      fill: $white;
      transition: fill 0.5s ease;
    }
  }
}


/*-----------------------------------------------
 * Fixed Social
 -----------------------------------------------*/

.main-social {
  position: fixed;
  bottom: 60px;
  left: 60px;
  z-index: 10;
  transform: translateY(2px);
  @include media-breakpoint-down(md) {
    left: 30px;
    bottom: 30px;
  }
  &__button {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 30px;
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: $black;
      transition: background-color 0.6s ease;
      .is-white-el & {
        background-color: $white;
      }
    }
    &::before {
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
    }
    &::after {
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      transition: transform 0.6s $cubic-ease, background-color 0.6s ease;
    }
    &:hover {
      &::before,
      &::after {
        background-color: $secondary;
        .is-white-el & {
          background-color: $white;
        }
      }
    }
  }
  .social-wrapper {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    width: 360px;
    height: 25px;
    position: relative;
    @include media-breakpoint-down(md) {
      // font-size: 1.1rem;
    }
  }
  .social {
    position: absolute;
    // top: -0.25em;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.6s $cubic-ease;
  }
  &.is-open {
    .social {
      transform: translateX(0);
    }
    .main-social__button::after {
      transform: rotate(90deg);
    }
  }
}

.social-plus {
  .icon svg {
    width: 23px;
    height: 23px;
    * {
      fill: $main;
      .is-white-el & {
        fill: $white;
      }
    }
  }
  &.is-pinterest {
    .icon svg {
      width: 20px;
      height: 20px;
    }
  }
  .social__link:hover .icon svg * {
    fill: $dark-bg;
  }
}