/**
 *  -----------------------------------------------
 *  GLOBAL
 *  -----------------------------------------------
 */


/*-----------------------------------------------
 * Wrappers
 -----------------------------------------------*/

html {
  font-size: 10px;
  @include media-breakpoint-up(lg) {
    height: 100%;
  }
  @include media-breakpoint-up(md) {
    &.no-touchevents {
      height: 100%;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: $text;
  font-size: 1.6rem;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  transition: background-color 1.3s $cubic-ease;
  background-color: $light-bg;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  @include media-breakpoint-up(lg) {
    overflow: hidden;
    height: 100%;
  }
  .no-touchevents & {
    @include media-breakpoint-up(md) {
      overflow: hidden;
      height: 100%;
    }
    .scrollbar-track-x {
      display: none !important;
    }
  }
}


/*-----------------------------------------------
 * Custom resets
 -----------------------------------------------*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  outline: none;
}

a {
  color: inherit;
  &,
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
  }
}

input,
textarea,
select,
button {
  &:focus {
    outline: none;
  }
}

// Remove Safari shadow
input,
textarea {
  border-radius: 0; // Reset default styles in iOs
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  line-height: 1.14;
  outline: 0;
  padding: 0;
  text-align: left;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

// Prevents "x" to clear field in explorer 11+
::-ms-clear {
  display: none;
}

.text {
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.03em;
  line-height: 1.8;
  font-family: $text;
  @include media-breakpoint-down(sm) {
    font-size: 1.6rem;
  }
}


/*-----------------------------------------------
 * Utils
 -----------------------------------------------*/

.no-padding {
  padding: 0;
}

.butler {
  font-family: $butler;
  font-weight: 300;
}

.main-wrapper {
  height: 100%;
}

.main-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  &.is-active {
    display: block;
  }
}

/*-----------------------------------------------
 * JS Class
 -----------------------------------------------*/

.js-scroll-container {
  overflow: hidden;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  transform: none;
  transition: transform 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s;
  .scroll-content {
    min-height: 100%;
  }
}

.scrollbar-track {
  background: transparent!important;
}


/*-----------------------------------------------
 * Animations
 -----------------------------------------------*/

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.js-to-fadein {
  opacity: 0;
}

.is-fadein {
  opacity: 0;
  animation: fadeIn 1.5s ease-out forwards;
}

.fadein {
  opacity: 1 !important;
  visibility: visible!important;
}

.fadeout {
  opacity: 0 !important;
}