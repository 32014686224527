/**
 *  -----------------------------------------------
 *  BLOCKQUOTE
 *  -----------------------------------------------
 */

.blockquote {
  @extend .section-mb__180;
  text-align: center;
  &__text {
    @extend .medium-title;
    display: block;
    max-width: 60vw;
    margin: 0 auto;
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    .is-white & {
      color: $white;
    }
  }
  @include media-breakpoint-down(md) {
    &__text {
      max-width: 90%;
    }
  }
  @include media-breakpoint-down(sm) {
    &__text {
      max-width: none;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}